<template>
  <div class="app-container">
    <div>
      <file-upload
        upload-route="surcharge/import"
        :append-entity-id="false"
        @fileImported="processFileImported"
      ></file-upload>
    </div>
    <div class="spaced">
      <CrudTable
        ref="table"
        entity="surcharge"
        :columns="columns"
        :load-data-on-created="true"
        :filters="defaultFilter"
        :allow-actions="false"
      />
    </div>
    <div v-if="showValidationErrors">
      <div class="validation spaced">{{ $t('hermes.validationErrorsMessage') }}</div>
      <ul>
        <li v-for="validationError in validationErrors.slice(0, maxValidationErrors)" :key="validationError">
          {{ validationError }}
        </li>
      </ul>
      <div v-if="showTotalNumberOfErrors" class="validation">
        {{ $t('hermes.validationErrorsLimitted', [maxValidationErrors, validationErrors.length]) }}
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/DSE/FileUpload';
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';

export default {
  components: { FileUpload, CrudTable },
  mixins: [crudIndexMixin],
  data() {
    return {
      maxValidationErrors: 20,
      validationErrorsCount: 0,
      baseFilter: 0,
      validationErrors: [],
      columns: [
        { field: 'parent', label: 'hermes.parent' },
        { field: 'client', label: 'hermes.client' },
        { field: 'brand', label: 'hermes.brand' },
        { field: 'originOfParcel', label: 'hermes.originOfParcel' },
        { field: 'barcode', label: 'hermes.barcode' },
        { field: 'previousBarcode', label: 'hermes.previousBarcode' },
        { field: 'invoiceTriggerDate', label: 'hermes.invoiceTriggerDate', type: 'date' },
        { field: 'hermesYearWeek', label: 'hermes.hermesYearWeek', type: 'number' },
        { field: 'clientYearPeriod', label: 'hermes.clientYearPeriod', type: 'number' },
        { field: 'customerReferenceNumber1', label: 'hermes.customerReferenceNumber1' },
        { field: 'customerReferenceNumber2', label: 'hermes.customerReferenceNumber2' },
        { field: 'chargeType', label: 'hermes.chargeType' },
        { field: 'reason', label: 'hermes.reason' },
        { field: 'events', label: 'hermes.events' },
        { field: 'invoiceDescription', label: 'hermes.invoiceDescription' },
        { field: 'totalRate', label: 'hermes.totalRate', type: 'currency' },
        { field: 'invoiceRate', label: 'hermes.invoiceRate', type: 'currency' },
        { field: 'surchargeDescription', label: 'hermes.surchargeDescription' },
        { field: 'surchargeRate', label: 'hermes.surchargeRate' },
        { field: 'hermesInvoiceNumber', label: 'hermes.invoiceNumber' }
      ]
    };
  },
  computed: {
    defaultFilter() {
      return { surchargeID: this.baseFilter };
    },
    showValidationErrors() {
      return this.validationErrors.length > 0;
    },
    showTotalNumberOfErrors() {
      return this.validationErrors.length > this.maxValidationErrors;
    }
  },
  methods: {
    processFileImported(response) {
      this.baseFilter = response.firstOfID;
      this.validationErrors = response.validationErrors;
    }
  }
};
</script>

<style>
.validation {
  color: red;
}
.spaced {
  margin-top: 20px;
}
</style>
